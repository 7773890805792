body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 105px 0 0;
}

body.embed {
  margin: 0!important;
}

@media (max-width: 959.95px) {
  body {
    margin-top: 85px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-prev {
  left: 0!important;
}
.slick-next {
  right: 0!important;
}
.slick-prev:before, .slick-next:before {
  opacity: 1!important;;
  color: #cccccc!important;;
  font-weight: 800!important;;
}
.slick-slide > div > div {
  outline: none;
}

.highcharts-container text,
.highcharts-container tspan {
  font-family: Manrope, sans-serif;
}
